import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Tooltip } from "antd";
import { createTeleporter } from "react-teleporter";

import SpectroLogo from "assets/icons/spectro_logo_white.svg?react";

import * as colors from "utils/constants/colors";

import Flags from "components/ui/Flags";
import Icon from "components/ui/Icon";
import { TextButton } from "components/ui/Button";
import AppVersion from "components/common/layouts/App/AppVersion";
import ProductTourModal from "./ProductTourModal";
import AppHeader from "./AppHeader";
import { pageWidthConstrain } from "components/styled/mixins";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import Link from "components/common/History/Link";
import PlanContainer from "./PlanContainer";
import { useMenu } from "components/common/providers/Menu";
import { useTranslation } from "react-i18next";
import appEnv from "services/app";
import MobileAlert from "components/common/MobileAlert";
import QuotaUtilization from "./QuotaUtilization";
import { useTheme } from "components/common/Theme";
import AppRefreshModal from "./AppRefreshModal";

const StyledLayout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 22px;
  height: 100%;
  width: 100%;
  max-height: 64px;
  transition: padding-left 0.3s ease-in;

  label {
    margin-left: 22px;
    transition: all 0.3s ease-in;
    font-size: 14px;
    line-height: 22px;
    opacity: 0;
    cursor: pointer;
  }
  > .anticon {
    font-size: 20px;
    svg {
      width: 20px;
    }
  }
`;

const MenuWrap = styled.div`
  display: flex;
  width: ${(props) => (props.expanded ? "245px" : "64px")};
  min-width: 64px;
  overflow: hidden;
  background: #080c26;
  box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease-in;

  ${(props) =>
    props.isDarker &&
    css`
      background: ${colors.midnightBlue};
    `}

  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
    `}

  ${(props) =>
    props.expanded &&
    css`
      ${IconWrap} {
        padding-left: 24px;

        label {
          margin-left: 16px;
          opacity: 1;
        }
      }
    `}
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-width: 64px;
  justify-content: space-between;

  &.menu-swap-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  &.menu-swap-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  &.menu-swap-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  &.menu-swap-exit-active {
    opacity: 0;
    transform: translateX(100%);
  }
  &.menu-swap-enter-active {
    transition: opacity 280ms ease-out, transform 280ms ease-out;
  }
  &.menu-swap-exit-active {
    transition: opacity 280ms ease-in, transform 280ms ease-in;
  }
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;

  ${(props) =>
    props.bottom &&
    css`
      overflow-y: visible;
      justify-content: flex-end;
    `}
`;

const Content = styled.div`
  background: ${colors.concreteWhite};
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;

  > .ant-layout-content {
    padding: 64px;
    position: relative;
    background: none;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
`;

const LogoWrap = styled.div`
  overflow: hidden;
  transition: all 0.3s ease-in;
  width: 41px;
  margin-left: 12px;

  ${(props) =>
    props.customLogoStyle &&
    css`
      svg {
        width: 131px;
        height: auto;
      }
    `}

  ${(props) =>
    props.resizeOnCollapse &&
    css`
      img {
        max-width: 100%;
        height: auto;
      }
    `}
`;

const LogoContent = styled.div`
  display: flex;
  height: 74px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-height: 550px) {
    height: 111px;
  }

  overflow: hidden;

  ${(props) =>
    props.expanded &&
    css`
      ${LogoWrap} {
        width: 100%;
        margin-left: 24px;
      }
    `}
`;

const MenuLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 30px;
  height: 64px;
  background: rgba(0, 0, 0, 0);
  .anticon {
    font-size: 18px;
  }

  svg path {
    fill: rgba(255, 255, 255, 0.5);
  }

  ${(props) =>
    props.selected &&
    css`
      color: ${colors.white};
      background: rgba(255, 255, 255, 0.1);
      svg path {
        fill: ${colors.white};
      }
    `}

  &:hover {
    color: ${colors.white};
    background: rgba(255, 255, 255, 0.1);
    svg path {
      fill: ${colors.white};
    }
  }

  transition: all 0.2s ease-in-out;

  &.back-to-project {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

const LayoutContent = styled.div`
  padding: 16px 32px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  flex: auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;

const HeaderWrap = styled.div`
  width: 100%;
  padding: 0 32px;
  background-color: ${colors.white};
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.03));
  z-index: 3;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`;

const StyledSpectroLogo = styled(SpectroLogo)`
  transition: opacity 0.3s ease-in;
  opacity: 0;
  path {
    fill: ${colors.white};
  }

  ${(props) =>
    props.expanded &&
    css`
      opacity: 1;
    `}
`;

const StyledExpandButton = styled(TextButton)`
  padding-left: 16px;
  padding-right: 22px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 48px;
  opacity: 0.5;

  &,
  &:hover,
  &:focus {
    color: ${colors.white};
  }

  &:hover {
    opacity: 1;
  }
`;

const ExpandIconWrap = styled.div`
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderContent = styled.div`
  ${pageWidthConstrain};
`;

export const contentRef = React.createRef();
export const PageHeaderSlot = createTeleporter();
export const PageToolbarSlot = createTeleporter();
export const BreadcrumbSlot = createTeleporter();

function AppLayout(props) {
  let activeKey = "/";
  const location = props.history.location.pathname;
  const adminMode = !!props.currentContext?.isAdmin;
  const userMenuItems = props.userMenuItems;
  const isDevMode = props.isDevMode;
  const { t } = useTranslation();

  const { expanded, setExpanded } = useMenu();
  const {
    SideMenuLogo,
    customMenuLogoStyle,
    showSidebarBottomLogo,
    resizeOnCollapse,
    sidebarBackground,
  } = useTheme({
    isMenuExpanded: expanded,
    isAdminMode: adminMode,
  });

  const bottomMenuItems = useMemo(() => {
    return userMenuItems.filter((item) => item.placement === "bottom");
  }, [userMenuItems]);

  const topMenuItems = useMemo(() => {
    return userMenuItems.filter((item) => item.placement !== "bottom");
  }, [userMenuItems]);

  if (location.startsWith("/projects/")) {
    activeKey = location.split("/")[3];
  }

  if (location.startsWith("/admin/")) {
    activeKey = location.split("/")[2];
  }

  if (location.startsWith("/dev/")) {
    activeKey = location.split("/")[4];
  }

  function renderMenuItems(item, index) {
    return (
      <Flags has={item.flags} key={index}>
        <Tooltip
          title={!expanded ? item.label() : undefined}
          placement="right"
          color={colors.darkBlue}
        >
          <MenuLink
            key={item.key}
            to={item.path}
            selected={
              Array.isArray(item.key)
                ? item.key.includes(activeKey)
                : activeKey === item.key
            }
            data-qa-type={adminMode ? "admin" : "project"}
            data-qa={item.key}
          >
            <IconWrap>
              <Icon
                theme={item.theme}
                component={item.component}
                awesome={item.awesome}
              />
              <label>{item.label()}</label>
            </IconWrap>
          </MenuLink>
        </Tooltip>
      </Flags>
    );
  }

  return (
    <StyledLayout>
      <MenuWrap
        expanded={expanded}
        isDarker={adminMode}
        background={sidebarBackground}
      >
        <SwitchTransition>
          <CSSTransition key={adminMode} classNames="menu-swap" timeout={140}>
            <Menu>
              <Link to="/">
                <LogoContent adminMode={adminMode} expanded={expanded}>
                  <LogoWrap
                    customLogoStyle={customMenuLogoStyle}
                    resizeOnCollapse={resizeOnCollapse}
                  >
                    <SideMenuLogo />
                  </LogoWrap>
                  <AppVersion
                    version={appEnv.env.PALETTE_VERSION}
                    expanded={expanded}
                  />
                </LogoContent>
              </Link>
              <MenuItems>{topMenuItems.map(renderMenuItems)}</MenuItems>
              <MenuItems bottom>
                {bottomMenuItems.map(renderMenuItems)}
              </MenuItems>
              {isDevMode && <QuotaUtilization />}
              <PlanContainer />
              <Divider />
              <Tooltip
                title={
                  expanded ? t("Collapse the sidebar") : t("Expand the sidebar")
                }
                placement="right"
                color={colors.darkBlue}
              >
                <StyledExpandButton
                  data-qa="expand-menu"
                  onClick={() => setExpanded((expanded) => !expanded)}
                >
                  {showSidebarBottomLogo ? (
                    <StyledSpectroLogo expanded={expanded} />
                  ) : (
                    <span />
                  )}
                  <ExpandIconWrap>
                    <Icon awesome={expanded ? faChevronLeft : faChevronRight} />
                  </ExpandIconWrap>
                </StyledExpandButton>
              </Tooltip>
            </Menu>
          </CSSTransition>
        </SwitchTransition>
      </MenuWrap>
      <Content>
        <HeaderWrap>
          <HeaderContent>
            <AppHeader {...props} />
            <PageHeaderSlot.Target />
          </HeaderContent>
        </HeaderWrap>
        <LayoutContent ref={contentRef}>{props.children}</LayoutContent>
        <ProductTourModal />
        <AppRefreshModal />
      </Content>
      <MobileAlert />
    </StyledLayout>
  );
}

export default withRouter(AppLayout);
